import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppInstall from 'components/modal/AppInstall';

import Home from './pages/Home';
import History from './pages/History';
import Statistics from './pages/Statistics';
import Policies from './pages/policies/Policies';
import Settings from './pages/settings/Settings';
import GameCasino from './pages/games/Casino';
import GameSlot from './pages/games/Slot';
import GamePlay from './pages/games/Play';
import Providers from './pages/games/Providers';
import Layout from './Layout';
import LoadingGIF from 'assets/Content/info/loading.gif'
import Affiliate from './pages/affiliate/Affiliate';
// import Licenses from './pages/Licenses';
import Promotions from './pages/Promotions';
import NotFound from 'pages/error/NotFound';
import { getServerStateAPI } from 'api/pragmatic'

import { GoogleOAuthProvider } from '@react-oauth/google';

/* aws login start */
import { Amplify } from 'aws-amplify';
import config from './aws-exports.js';
import useAuth from 'hooks/useAuth';
import { getLanguage, setSessionLoading } from 'store/modules/user';
import { useTranslation } from 'react-i18next'

/* aws login end */

/*
redirect urls for aws cognito
https://acecasino.io/,http://localhost:3000/,https://test.acecasino.io/,https://www.acecasino.io/
find the url that matches the current hostname

acecasino.io = https://acecasino.io/
localhost = http://localhost:3000/
test.acecasino.io = https://test.acecasino.io/
www.acecasino.io = https://www.acecasino.io/
 */
const redirectSignIn = config.oauth.redirectSignIn.split(",").find((i) => ((i.match(/^(?:https?:\/\/)?([^\/:]+)(?:[\/:]|$)/i)||[])[1] == window.location.hostname))
const redirectSignOut = config.oauth.redirectSignOut.split(",").find((i) => ((i.match(/^(?:https?:\/\/)?([^\/:]+)(?:[\/:]|$)/i)||[])[1] == window.location.hostname))

// split redirect signin and signout strings into correct URIs
// const [
//   productionRedirectSignIn,
//   localRedirectSignIn] = config.oauth.redirectSignIn.split(",");
// const [
//   productionRedirectSignOut,
//   localRedirectSignOut] = config.oauth.redirectSignOut.split(",");

// use correct URI in the right env
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: redirectSignIn,
    redirectSignOut: redirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

function App() {
  const { setSessionToken, setInitUserData } = useAuth();
  const dispatch = useDispatch();
  /* aws login start */
  const storeUser = useSelector(state => { return state?.user; });
  const { userInfo, language: userLanguage, sessionLoading, isLoggedIn, jwtToken, idToken } = storeUser;

  useEffect(() => {
    setSessionToken()
  }, []);

  useEffect(() => {
    const userEmail = idToken?.email;
    if (userEmail && jwtToken && !userInfo) {
      setInitUserData(userEmail)
    }
  }, [idToken, userInfo]);

  /* aws login end */

  useEffect(() => {
    dispatch(getLanguage());
  }, [userLanguage, dispatch]);

  return (
    <GoogleOAuthProvider clientId="347323537061-ujo0rlpuhsasgk213t9e1jisash4n6c3.apps.googleusercontent.com">
    {sessionLoading ?
      <div className="loading-wrapper"
        open={true}>
        <div className="loading-wrapper__inner">
          <img src={LoadingGIF} alt="loading gif img" />
        </div>
      </div> :
      <Routes>
      {<Route path="/" element={<Navigate replace to={`/${userLanguage}`} />} />}
          <Route path='/:language' element={<Layout />} >
            <Route index element={<Home />} />
            <Route path='campaign/:code' element={<Home />} />
            <Route path='managedRegister' element={<Home />} />
            <Route path='managedLogin' element={<Home />} />
            <Route path='casino' element={<GameCasino />} />
            <Route path='slot' element={<GameSlot />} />
            <Route path='providers/:providerId' element={<Providers />} />
            <Route path='policies/:section' element={<Policies />} />
            {/* <Route path='licenses' element={<Licenses />} /> */}
            {isLoggedIn ? (
              <>
                <Route path='casino/games/:gameId' element={<GamePlay />} />
                <Route path='slot/games/:gameId' element={<GamePlay />} />
                <Route path='affiliate/:section' element={<Affiliate />} />
                <Route path='history' element={<History />} />
                <Route path='statistics' element={<Statistics />} />
                <Route path='settings/:section' element={<Settings />} />
                <Route path='promotion' element={<Promotions />} />
              </>
              ) : null}
          </Route>
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    }
    <AppInstall />
    </GoogleOAuthProvider>
  );
}

export default App;