/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://oczb2oma7rd6tpwem2emfpv2xe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-23k3coipfvhk3ljwv4cpjphydy",
    "aws_cognito_identity_pool_id": "us-east-1:45dd66e4-9dc5-47c2-a8bd-65a2958311a4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KaOdpZeVu",
    "aws_user_pools_web_client_id": "rue4pa2h92absub813ppoe7c8",
    "oauth": {
        "domain": "i2euv7yctekl-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://acecasino.io/,http://localhost:3000/,https://test.acecasino.io/,https://www.acecasino.io/,https://test.d11foezu5gud8o.amplifyapp.com/,https://prod.d11foezu5gud8o.amplifyapp.com/,http://localhost:5000/",
        "redirectSignOut": "https://acecasino.io/,http://localhost:3000/,https://test.acecasino.io/,https://www.acecasino.io/,https://test.d11foezu5gud8o.amplifyapp.com/,https://prod.d11foezu5gud8o.amplifyapp.com/,http://localhost:5000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
