import { useTranslation } from 'react-i18next'
import { getProviderListAPI } from 'api/pragmatic'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { movePath } from 'utils/movePath';

export default function ProviderListItem(props) {
  const { t } = useTranslation();
  const nav = useNavigate();
  const lang = useSelector(state => {
    return state?.user.language;
  });

  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProviders = async () => {
      setLoading(true);
      try {
        const response = await getProviderListAPI();
        for (let index = 0; index < response.length; index++) {
          let item = response[index];
          if (item.code === "") {
            response.splice(index, 1);
            break;
          }
        }
        setProviders(response);
      } catch (error) {
        console.error('Failed to fetch providers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProviders();
  }, []);

  if (loading) return <div>로딩중...</div>;

  return (
    <section className="provider-list-wrapper">
      <div className="provider-list-header">
        <div className="title-info">
          <i className="icon-set news"></i>
          <p>{t('pages.home.provider.title', '제공자')}</p>
        </div>
      </div>
      <div className="provider-list">
        {providers.map((provider) => (
          <div key={provider.id} className="provider-item">
            <img 
              src={provider.image} 
              alt={provider.title} 
              onClick={() => movePath(nav, lang, `/providers/${provider.code}`)} 
              style={{ cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
