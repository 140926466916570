import { apiRequest } from 'utils/axios';
import logo_pragmatic from 'assets/providers/PRAGMATICPLAY.png'
import logo_evolution from 'assets/providers/EVOLUTION.png'
import logo_bigtimegaming from 'assets/providers/BIGTIMEGAMING.png'
import logo_netent from 'assets/providers/NETENT.png'
import logo_nolimit from 'assets/providers/NOLIMIT.png'
import logo_redtiger from 'assets/providers/REDTIGER.png'

const providerList = {
  'pragmatic': logo_pragmatic,
  'evolution': logo_evolution,
  'bigtimegaming': logo_bigtimegaming,
  'netent': logo_netent,
  'nolimit': logo_nolimit,
  'redtiger': logo_redtiger
}

/**
 * 게임 리스트 조회 API
 */
// export const getGameListAPI = async (category) => {
//   return new Promise((resolve, reject) => {
//     const url = `/get-casino-games?category=${category}`;
//     apiClient.get(url)
//     .then(res => {
//       resolve(res.data);
//     })
//     .catch(e => {
//       reject(e);
//     })
//   })
// }

/**
 * 게임 리스트 조회 API
 * @param {number} providerId 생략가능 (전체): Live casino, lg, Slot, vs : 4가지 입력 받을수 있으며 Live casino == lg, Slot == vs 와 같습니다.
 * @param {string} gameType 생략가능 (전체): Live casino, lg, Slot, vs : 4가지 입력 받을수 있으며 Live casino == lg, Slot == vs 와 같습니다.
 * @param {string} search 생략가능(전체): 검색 키워드 입력 대소문자 무시
 * @param {string} sort 생략가능(game_name): game_name 만 가능합니다.
 * @param {string} sortOrder 생략가능(ASC) DESC
 * @param {number} limit 생략가능(30)
 * @param {number} page 생략가능(1)
 */
export const getGameListAPI = async (providerId, gameType, search, page, limit, sortOrder, sort) => {
  try {
    let url = `/get-casino-game-search?providerId=${providerId}`;
    if (gameType) {
      url += `&gameType=${gameType}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (sortOrder) {
      url += `&sortOrder=${sortOrder}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (page) {
      url += `&page=${page}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 게임 리스트 조회 API
 * @param {string} gameType 생략가능 (전체): Live casino, lg, Slot, vs : 4가지 입력 받을수 있으며 Live casino == lg, Slot == vs 와 같습니다.
 * @param {string} search 생략가능(전체): 검색 키워드 입력 대소문자 무시
 * @param {string} sort 생략가능(game_name): game_name 만 가능합니다.
 * @param {string} sortOrder 생략가능(ASC) DESC
 * @param {number} limit 생략가능(30)
 * @param {number} page 생략가능(1)
 */
export const getProviderGameListAPI = async (providerId, search, page, limit, sortOrder, sort) => {
  try {
    let url = `/get-casino-game-search?providerId=${providerId}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (sortOrder) {
      url += `&sortOrder=${sortOrder}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (page) {
      url += `&page=${page}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


/**
 * 게임 실행 링크 조회 API
 *
 * @param {string} symbol game list 에서 받은 gameID (vs20olympgate)
 * @param {string} currency Real 게임일 시 입력
 * @param {string} email 유저 이메일
 * @param {string} playMode DEMO
 */
export const getStartGameRequestAPI = async (symbol, currency, email, playMode, cancelToken) => {
  try {
    let url = `/StartGameRequest?symbol=${symbol}&email=${email}`
    if (playMode) {
      url += `&playMode=${playMode}`;
    }
    if (currency) {
      url += `&currency=${currency}`;
    }
    const result = await apiRequest({
      method: 'get',
      url,
    }, cancelToken);
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 게임 세션 종료 API
 *
 * @param {string} email 유저 이메일
 */
export const sessionTerminateAPI = async (email) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/pragmatic/sessionTerminate`,
      data: { email }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


/**
 * 리딤 코드 등록
 */
export const postRedeemCodeAPI = async (email, code) => {
  try {
    const result = await apiRequest({
      method: 'post',
      url: `/pragmatic/redeemCode`,
      data: { email, code }
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

/**
 * 점검 중 유무 API
 */
export const getServerStateAPI = async () => {
  try {
    const result = await apiRequest({
      method: 'get',
      url: `/serverstate`,
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getProviderListAPI = async () => {
  try {
    // 실제 API 구현 전까지 목업 데이터 반환
    const result = await apiRequest({
      method: 'get',
      url: `/get-provider-list`,
    });
    const providers = [ 
      { id: 0, code: "", title: 'All', value: ""},
      { id: 1, code: "pragmatic play", title: 'Pragmatic Play', image: providerList.pragmatic },
      { id: 2, code: "evolution", title: 'Evolution', image: providerList.evolution },
      { id: 3, code: "redtiger", title: 'RedTiger', image: providerList.redtiger },
      { id: 4, code: "netent", title: 'NetEnt', image: providerList.netent },
      { id: 5, code: "btg", title: 'BigTimeGaming', image: providerList.bigtimegaming },
      { id: 6, code: "nlc", title: 'NoLimit', image: providerList.nolimit },
    ]

    let allCount = 0;
    for (const provider of result) {
      for (const rProvider of providers) {
        if (provider.provider_name === rProvider.code) {
          rProvider.game_count = provider.game_count;
          rProvider.value = provider.provider_name;
          allCount += provider.game_count;
        }
      }
    }
    providers[0].game_count = allCount;
    return providers;
  } catch (error) {
    console.error('getProviderListAPI error:', error);
    throw error;
  }
};
