import GameList from './GameList';
import { useParams } from 'react-router-dom';

export default function Providers() {
  const { providerId } = useParams();
  const data = {
    providerId: providerId,
    title: "pages.home.provider.title",
    iconName: "casino"
  }

  return (
    <>
      <GameList {...data} />
    </>
  )
}