import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { movePath } from 'utils/movePath';
import { getGameListAPI } from 'api/pragmatic'
import { openModal } from 'store/modules/components';
import { setManagedRegisterData } from 'store/modules/user';
import Button from '@mui/material/Button';
import SetSwiper from 'components/slider/SetSwiper'
import LinearPropgress from 'components/ui/LinearPropgress'
import BaseButton from 'components/ui/button/BaseButton';
import MainBanner from 'components/slider/MainBanner'
import { getNextTierInfo } from 'utils/userTier';
import Licenses01IMG from 'assets/Content/info/licenses-01.png';
import Licenses02IMG from 'assets/Content/info/licenses-02.png';
import { useTranslation } from 'react-i18next'
import { setCookie } from 'utils/cookie';
import { checkEmailAPI } from 'api/user';
import useAuth from 'hooks/useAuth';

import { SNS_ITEMS } from 'constants/linkInfoList'
import LinkListItem from 'components/item/LinkListItem';
import ProviderListItem from 'components/item/ProviderItem';
import Dialog from '@mui/material/Dialog';

function LoggedInLayout() { // 로그인상태
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openVipModal = () => { dispatch(openModal({ modalType: "Vip" })); };
  const user = useSelector(state => {
    return state?.user;
  });
  const { userInfo, betTotal } = user;
  const { percentageToNextTier } = getNextTierInfo(betTotal.currentTier, betTotal.wagerAmount)
  return (
    <div className="logged-in">
      <p className="logged-in__msg">{t('pages.home.banner.loggedIn.title', { username: userInfo.username })}</p>
      {/* <div className="user-grade">
        <div className="user-grade__header">
          <div className="user-state-grade">
            <p dangerouslySetInnerHTML={{ __html: t('pages.home.banner.loggedIn.Progress', { currentTier: betTotal?.currentTier }) }}></p>
          </div>
          <p className="user-state-value">{(percentageToNextTier && !isNaN(percentageToNextTier)) ? percentageToNextTier.toFixed(2) : "0"}%</p>
        </div>
        <LinearPropgress value={percentageToNextTier} />
        <div className="user-grade__step">
          <div className="row-item">
            {betTotal?.currentTier && <img src={require(`assets/Grade/${betTotal?.currentTier}.png`)} alt={`${betTotal?.currentTier} icon`} />}
            <p>{betTotal?.currentTier}</p>
          </div>
          <div className="row-item">
            {betTotal?.nextTier && <img src={require(`assets/Grade/${betTotal?.nextTier}.png`)} alt={`${betTotal?.nextTier} icon`} />}
            <p>{betTotal?.nextTier}</p>
          </div>
        </div>
      </div>
      <BaseButton onClick={openVipModal} color="gray" label="pages.home.banner.loggedIn.vipBtn" size="small" /> */}
    </div>
  )
}

function LoggedOutLayout() { // 로그아웃상태
  const { snsLogin } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openRegisterModal = () => { dispatch(openModal({ modalType: "Register" })); };

  return (
    <div className="logged-out">
      <div className="desc">
        <p className="desc__title" dangerouslySetInnerHTML={{ __html: t('pages.home.banner.loggedOut.title') }}></p>
        <span className="desc__info">{t('pages.home.banner.loggedOut.subTitle')}</span>
      </div>
      <div className="login-wrapper">
        <BaseButton color="primary" onClick={openRegisterModal} label="pages.home.banner.loggedOut.registerBtn" />
        <span className="or">{t('component.modal.signIn.or')}</span>
        <div className="sns-login">
          <Button onClick={() => snsLogin("Google")} className="icon-r login-google"></Button>
          {/* <Button onClick={()=>snsLogin("Facebook")} className="icon-r login-facebook"></Button> */}
          {/* <Button className="icon-r login-whatsapp"></Button> */}
        </div>
      </div>
    </div>
  )
}
function UserStatusBanner(props) {
  const isLogged = props.isLogged;
  return (
    // <section className="user-status-mainbanner-wrapper bg-wrap layer-02 re-320">
    <section className="user-status-mainbanner-wrapper bg-wrap layer-02">
      <div className="user-status-mainbanner">
        {isLogged ? <LoggedInLayout /> : <LoggedOutLayout />}
        <MainBanner />
      </div>
    </section>
  )
}

function ListWrap() {
  const [newsList, setNewsList] = useState({
    groupInfo: {
      title: 'news',
      iconName: 'news',
      view: () => window.open("https://medium.com/@ACECASINO")
    },
    items: []
  });
  const [guideList, setGuideList] = useState({
    groupInfo: {
      title: 'guide',
      iconName: 'guide',
      view: () => window.open("https://medium.com/@ACECASINO")
    },
    items: []
  });

  const getMediumList = async () => {
    try {
      const name = "ACECASINO";
      const RSSUrl = `https://medium.com/feed/@${name}`;
      const api_key = "ks1xbeeajpgjirio6yozad0t5cb8ddbjvydxxolt"
      /*
        https://rss2json.com/me/api_key
        api key 발급
        id: api@sendsquare.co
        pw: mev@uaifnw0152**
       */
      const RSSConverter = `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(RSSUrl)}&api_key=${api_key}`;

      const response = await fetch(RSSConverter);
      const data = await response.json();

      const filterItemsByCategory = (category) =>
        data.items.filter((item) => item.categories.includes(category));

      const newsItems = filterItemsByCategory("news");
      const guideItems = filterItemsByCategory("gui̇de");

      const formatItems = (items) =>
        items.map((item) => ({
          title: item.title,
          link: item.link,
          date: item.pubDate,
        }));

      const newsList = formatItems(newsItems).slice(0, 4);
      const guideList = formatItems(guideItems).slice(0, 4);

      setNewsList((prevState) => ({ ...prevState, items: newsList }));
      setGuideList((prevState) => ({ ...prevState, items: guideList }));
    }
    catch (e) {
      console.log("medium RSSConverter e: ", e)
    }
  }

  useEffect(() => {
    getMediumList()
  }, [])

  return (
    <div className="noti-list-wrap">
      {newsList.items?.length ? <LinkListItem {...newsList} /> : null}
      {guideList.items?.length ? <LinkListItem {...guideList} /> : null}
    </div>
  )
}

function SliderWrap() {
  const nav = useNavigate();
  const lang = useSelector(state => {
    return state?.user.language;
  });
  const [casinoList, setCasinoList] = useState({
    groupInfo: {
      title: 'pages.games.title.casino',
      iconName: 'casino',
      view: () => movePath(nav, lang, "/casino")
    },
    items: []
  });
  const [slotList, setSlotList] = useState({
    groupInfo: {
      title: 'pages.games.title.slot',
      iconName: 'slot',
      view: () => movePath(nav, lang, "/slot")
    },
    items: []
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getGameList() {
      setLoading(true)
      try {
        const casino_list = await getGameListAPI("", "lg");
        const slot_list = await getGameListAPI("", "vs");
        setCasinoList(prevState => ({ ...prevState, items: casino_list?.games.slice(0, 36) }));
        setSlotList(prevState => ({ ...prevState, items: slot_list?.games.slice(0, 36) }));
        setLoading(false)
      } catch (e) {
        setLoading(false);
        console.log("e: ", e);
      }
    }
    getGameList();
  }, [])
  return (
    <div className="slider-list-wrap">
      <SetSwiper list={casinoList} loading={loading} />
      <SetSwiper list={slotList} loading={loading} />
    </div>
  )
}

function MaintenanceModal({ open, handleClose }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="base-dialog-wrapper content-dialog-wrapper small"
    >
      <div className="base-dialog-inner bottom-p-32">
        <div className="base-dialog-header">
          <p className="base-dialog-header__title medium">{t('component.modal.serverCheck.title')}</p>
          <button onClick={handleClose} className="close-btn"></button>
        </div>
        <div className="base-dialog-body notice">
          <div className="notice-wrapper">
            <div className="notice-wrapper__inner">
              <div className="maintenance-info">
                <p>{t('component.modal.serverCheck.info.1')}</p>
                <p>{t('component.modal.serverCheck.info.2')}</p>
              </div>
            </div>
            <BaseButton
              onClick={handleClose}
              color="primary"
              label="Confirm"
              size="large"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

function Home() {
  const { setManagedToken } = useAuth();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { code } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => { return state?.user; });
  const nav = useNavigate();
  const { isLoggedIn, language, userInfo } = user;
  const policyList = {
    "AML-KYC-Policy": "/policies/kyc-aml",
    "Privacy Policy": "/policies/policy",
    "Responsible Gambling": "/policies/reponsibleGambling",
    "Standard Terms and Conditions": "/policies/termsOfConditions"
  }
  const move = (value) => {
    movePath(nav, language, value)
  }
  const searchParams = new URLSearchParams(search);

  const checkEmail = async (value) => {
    if (!value) return;
    try {
      const res = await checkEmailAPI(value);
      if (res.count - 0 > 0) {
        return true;
      }
    } catch (e) {
      console.log('checkEmailAPI e:', e);
    }
  };

  const managedRegister = async () => {
    // managedRegister?email=email
    const email = searchParams.get('email');
    const name = searchParams.get('name');
    const addrbook = searchParams.get('addrbook');
    const campaignCode = searchParams.get('campaign');
    if (!userInfo) {
      dispatch(setManagedRegisterData({ email, name, addrbook }));
      const isExistEmail = await checkEmail(email);
      dispatch(openModal({ modalType: isExistEmail ? "SignIn" : "Register" }))
      if (!isExistEmail && campaignCode) {
        setCookie('referralCode', campaignCode);
      }
    } else {
      movePath(nav, language, '/');
    }
  }

  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);

  // useEffect(() => {
  //   const checkServerState = async () => {
  //     try {
  //       const serverState = await getServerStateAPI();
  //       if (serverState === "0") {
  //         setMaintenanceModalOpen(true);
  //       }
  //     } catch (e) {
  //       console.error('서버 상태 확인 실패:', e);
  //     }
  //   };

  //   checkServerState();
  // }, []);

  const handleClose = () => {
    setMaintenanceModalOpen(false);
  };

  useEffect(() => {
    if (pathname?.includes('campaign')) {
      setCookie('referralCode', code);
    }
    if (pathname?.includes('managedRegister')) {
      managedRegister()
    } else {
      dispatch(setManagedRegisterData({ email: "", name: "", addrbook: "" }));
    }
    // managedLogin?email=email&token=token
    if (pathname?.includes('managedLogin')) {
      const email = searchParams.get('email');
      const token = searchParams.get('token');
      setManagedToken(token, email)
    }
  }, [pathname, userInfo])

  return (
    <div className="home-wrapper">
      <UserStatusBanner isLogged={isLoggedIn} />
      <div className="home-inner">
        <ProviderListItem />
        <SliderWrap />
        <ListWrap />
        <section className="sns-link-wrapper">
          <p className="sns-link-wrapper__title">{t('pages.home.sns.info')}</p>
          <div className="sns-link-inner">
            {SNS_ITEMS.map((item, index) => {
              return <Button key={index} onClick={() => window.open(item.link)} className={`sns-btn ${item.icon}`}>{item.name}</Button>
            })}
          </div>
        </section>
        <section className="policy-viewer">
          <div className="policy-viewer__header">
            <div className="title">
              <i className="icon-set policy"></i>
              <p>{t('pages.home.policy.title')}</p>
            </div>
          </div>
          <div className="policy-viewer__body">
            {Object.entries(policyList).map(([key, value], index) => {
              return <BaseButton onClick={() => move(value)} color="gray" label={key} size="large" key={index} />
            })}
          </div>
        </section>
        <section className="licenses-viewer">
          <div className="licenses-viewer__desc">
            <div className="title">
              <i className="icon-set licenses"></i>
              <p>{t('pages.home.licenses.title')}</p>
            </div>
            <p className="info">{t('pages.home.licenses.info')}</p>
          </div>
          <div className="licenses-viewer__symbol">
            <div className="box-img">
              <img className="licenses licenses01" src={Licenses01IMG} alt="Licenses 01 img" />
            </div>
            <div className="box-img">
              <img className="licenses licenses02" src={Licenses02IMG} alt="Licenses 02 img" />
            </div>
          </div>
        </section>
      </div>
      <MaintenanceModal
        open={maintenanceModalOpen}
        handleClose={handleClose}
      />
    </div>
  )
}

export default Home;
